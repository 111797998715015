import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RequestService, StoreService } from '../../shared/services';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private storeService: StoreService,
    private requestService: RequestService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log('AuthGuard#canActivate called');
    if (localStorage.getItem('currentUser')) {
      // console.log('AuthGuard#canActivate called with currentUser');
      // logged in so return true
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.requestService.currentUser = currentUser;
      this.requestService.currentUserSubject.next(currentUser);
      this.requestService.authenticatedUser.next(true);
      if (this.legitUser(currentUser)) {
        return true;
      }
    }
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
  private legitUser(user): boolean {
    if (user.hasOwnProperty('token')) {
      return true;
    } else {
      return false;
    }
  }
}
